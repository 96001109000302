<template>
  <alerts-index :fields="fields" :filters="filters" />
</template>

<script>
import Vue from 'vue';
import AlertsIndex from '~/pages/shared/alerts/AlertsIndex';

export default Vue.extend({
  components: {
    AlertsIndex,
  },

  middleware: 'employee',

  data: () => ({
    filters: [],

    fields: ['label', 'interval', 'created_at', 'actions'],
  }),
});
</script>
